import React, {
} from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// **************** IMPORT GIFS *************************** START
import CreateDrawingTutorialGif from './gifs/create-drawing-tutorial.gif'
// **************** IMPORT GIFS *************************** END


const CreateDrawingTutorial = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
      }}
    >
      <Typography
        variant="h5"
      >
        Tutorial: Create Drawing
      </Typography>
    </div>
    <Divider />
    <div
      style={{
        textAlign: 'center',
        height: '80%',
        width: '100%',
        backgroundColor: '#232323'
      }}
    >
      <img
        src={CreateDrawingTutorialGif}
        alt="logout_gif"
        style={{
          height: '100%',
        }}
      />
    </div>
    <Divider />
    <div
      style={{
        padding: '5px 10px',
        backgroundColor: '#3a3a3a',
      }}
    >
      <Typography
        variant="body2"
      >
        • Click on the &apos;✚&apos; icon on the Home Page.
        <br />
        • Wait for the drawing Canvas to be Created.
        <br />
        • Start Drawing!
        <br />
        • Don&apos;t forget to click on the &apos;ShareDraw&apos; Button to Share/Save your drawing!
        <br />
        • Note: the number of shares(saves) are limited per drawing
        (you can see the number in the bottom right).
        Make sure you and other collaborators stay within the limit!
        <br />
      </Typography>
    </div>
  </>
)


CreateDrawingTutorial.propTypes = {
}

CreateDrawingTutorial.defaultProps = {
}

export default CreateDrawingTutorial
