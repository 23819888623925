import React, {
  useState,
  useRef,
  useEffect,
  useContext
} from 'react';
import PropTypes from 'prop-types'
import BrushIcon from '@material-ui/icons/Brush'
import PerfectScrollbar from 'react-perfect-scrollbar'
import SaveIcon from '@material-ui/icons/Save'
import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Pagination from '@material-ui/lab/Pagination'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton';
import AppContext from '../appContext'
// ---------------- tutorial comp -------------------------
import LogoutTutorial from './LogoutTutorial'
import ViewUsageTutorial from './ViewUsageTutorial'
import CreateDrawingTutorial from './CreateDrawingTutorial'
import BrushSettingsTutorial from './BrushSettingsTutorial'
import ShareDrawingTutorial from './ShareDrawingTutorial'
import FinishDrawingTutorial from './FinishDrawingTutorial'
import UndoDrawingTutorial from './UndoDrawingTutorial'
import DeleteDrawingTutorial from './DeleteDrawingTutorial'

const tutorialPages = [
  <CreateDrawingTutorial />,
  <BrushSettingsTutorial />,
  <UndoDrawingTutorial />,
  <ShareDrawingTutorial />,
  <FinishDrawingTutorial />,
  <ViewUsageTutorial />,
  <DeleteDrawingTutorial />,
  <LogoutTutorial />,
]


const Tutorial = ({
  open, onClose, className, ...rest
}) => {
  // ************** USE CONTEXT *************************** START
  const appContext = useContext(AppContext)
  // ************** USE CONTEXT *************************** END

  // ************** USE STATE ***************************** START
  const [tutorialPageNum, setTutorialPageNum] = useState(0)
  // ************** USE STATE ***************************** END

  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          outline: 'none',
        }}
      >
        <CardContent
          style={{
            overflowY: 'auto',
            height: '74vh',
            padding: 0,
          }}
        >
          {tutorialPages[tutorialPageNum]}
        </CardContent>
        <Divider />
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Pagination
            count={tutorialPages.length}
            color="primary"
            page={tutorialPageNum + 1}
            onChange={(event, value) => {
              setTutorialPageNum(value - 1)
            }}
            siblingCount={0}
            boundaryCount={1}
          // size="small"
          />
          <IconButton
            onClick={onClose}
            style={{
              padding: 0,
            }}
          >
            <CancelIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Modal>
  )
}


Tutorial.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
}

Tutorial.defaultProps = {
  open: false,
  onClose: () => { }
}

export default Tutorial
