import React from 'react'

/**
 * App Context
 */
const appContext = React.createContext({
  authenticated: false,
  setAuthenticated: () => { },
  logout: () => { },
  onLogin: () => { },
  accessLevelNum: null,
  setAccessLevelNum: () => { },
  userUID: '',
  setUserUID: () => { },
  displayName: '',
  setDisplayName: () => { },
  backdropConfig: null,
  setBackdropConfig: () => { },
  alertMessage: null,
  setAlertMessage: () => {},
  sharesQuota: '',
  setSharesQuota: () => {},
  showTutorial: null,
  setShowTutorial: () => {},
})

export default appContext
