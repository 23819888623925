import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
} from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { createBrowserHistory } from 'history';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import CssBaseLine from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Backdrop from '@material-ui/core/Backdrop'
import Alert from '@material-ui/lab/Alert';
import routes from './routes';
import './App.scss';
import AppContext from './appContext'
import { logOutUser, updateAuthClaims } from './authService'
import { getAccessLevelNumFromId } from './accessLevels'
import { sleep } from './utils/sleep'
import TutorialModal from './Tutorial'
import firebase from './firebase';


const db = firebase.firestore() // init firestore DB
const history = createBrowserHistory();


const App = () => {
  // get access level from local store
  const access_level_id = localStorage.getItem('accessLevelID')
  const authToken = localStorage.getItem('authorization')
  const display_name = localStorage.getItem('display_name') || ''
  const user_uid = localStorage.getItem('user_uid') // user uid
  const shares_quota = localStorage.getItem('sharesQuota')
  const show_tutorial = localStorage.getItem('showTutorial')
  // ******************************************************
  // -------------- STATE CONFIG -------------------------- START
  // ******************************************************
  // -------------- :: Context :: State vars --------------
  const [authenticated, setAuthenticated] = useState(!!authToken)
  const [accessLevelNum, setAccessLevelNum] = useState(
    getAccessLevelNumFromId(access_level_id) || null
  )
  const [userUID, setUserUID] = useState(user_uid) // users uid
  const [displayName, setDisplayName] = useState(display_name)
  const [sharesQuota, setSharesQuota] = useState(shares_quota)
  const [showTutorial, setShowTutorial] = useState(show_tutorial === 'yes')
  // -------------- backdrop ------------------------------
  const [backdropConfig, setBackdropConfig] = useState({
    show: false,
    showProgress: false,
    showMessage: false,
    message: '',
  })
  // -------------- alert ---------------------------------
  const [alertMessage, setAlertMessage] = useState({
    show: false,
    message: '',
    severity: 'success',
  })
  // ****************************************************** END

  /** configure theme */
  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: 'dark', // set dark theme
        primary: blue,
      },
    }),
    [],
  );

  // ******************************************************
  // -------------- FUNCTION HANLDERS --------------------- START
  // ******************************************************

  /**
   * Listen to Access level updates on the DB
   * and update the local access level accordingly
   */
  const liveUpdateAccessLevel = useCallback(() => {
    let removeUserAccLevelListener = null
    try {
      if (userUID) {
        const docRef = db.collection('UserAccessLevel').doc(userUID)
        // listen to events on this doc
        removeUserAccLevelListener = docRef.onSnapshot((doc) => {
          // console.log("Current data: ", doc.data());
          const dat = doc.data()
          if (Object.keys(dat).length > 0) {
            const { accessLevelID, lastUpdated } = dat
            setAccessLevelNum(getAccessLevelNumFromId(accessLevelID))
            localStorage.setItem('accessLevelID', accessLevelID.toString()) // set in local store
          } else {
            throw new Error('dat is invalid!')
          }
        })
      } else throw new Error('userUID invalid!')
    } catch (err) {
      console.error(err)
    }
    return removeUserAccLevelListener
  }, [userUID])


  /**
   * Firebase refresh the JWT and get new ID
   * token result using the user object passed
   * to it on login
   * @param {*} user user Obj passed on login
   */
  const firebaseGetIdTokenResult = async (user) => {
    let removeListener = null
    firebase.auth().currentUser.getIdTokenResult(true) // get JWT
      .then((idTokenResult) => {
        setBackdropConfig({ // Set Updating User Details to false
          show: false,
        })
        const {
          token,
          claims,
        } = idTokenResult // get token, claims from idTokenResult
        const {
          name,
          accessLevelID,
          sharesQuota: shares,
        } = claims // get data from claims
        if (token && name && accessLevelID && shares) {
          setUserUID(user.uid) // set user uid
          localStorage.setItem('user_uid', user.uid) // user uid
          setDisplayName(name.toString()) // set in global state
          localStorage.setItem('display_name', name.toString()) // set name in local store
          setSharesQuota(shares) // set SharesQuota
          localStorage.setItem('sharesQuota', shares) // set SharesQuota in localstore
          removeListener = liveUpdateAccessLevel() // listen and set access level updates from db
          setAuthenticated(true) // set autherticated global state
          localStorage.setItem('authorization', token) // set auth token to local store
          // ---------------- set show tutorial -----------
          const show_tutorial_temp = localStorage.getItem('showTutorial')
          if (show_tutorial_temp) setShowTutorial(show_tutorial_temp)
          else {
            setShowTutorial(true) // show tutorial for first timer
            localStorage.setItem('showTutorial', 'yes')
          }
        } else {
          throw new Error('token / name / accessLevelID invalid!')
        }
      })
      .catch((err) => {
        console.error(err)
        if (removeListener) removeListener() // cleanup
      })
  }


  /**
   * To be Executed onLogin in the Login page
   * inside `signInSuccessWithAuthResult` function
   * @param {*} authResult Passed by the signInSuccessWithAuthResult function
   */
  const onLoginHandler = async (authResult) => {
    const { user } = authResult
    //  This gets called on signIn Success
    // User is signed in.
    setBackdropConfig({
      show: true,
      showProgress: true,
      showMessage: true,
      message: 'Updating User Details...'
    })
    await sleep(8000)
    await firebaseGetIdTokenResult(user)
  }


  /**
   * Logout and set set auth to false, reset
   */
  const logoutHandler = async () => {
    // eslint-disable-next-line prefer-const
    let output = {}
    let status = false
    let message = ''

    setBackdropConfig({
      show: true,
      showProgress: true,
      showMessage: true,
      message: 'Logging out, Please Wait...'
    })
    try {
      const uacResp = await updateAuthClaims() // refresh access level before logout
      if (!uacResp.status) throw new Error(`updateAuthClaims Failed! ${uacResp.message}`)
      console.log('updateAuthClaims', uacResp.message) // if sucess
    } catch (err) {
      message = `${err.toString()}`
      status = false
      console.error(message)
    } finally { // come what may
      const louResp = await logOutUser() // logout
      if (louResp.status) {
        console.log('logOutUser', louResp.message) // if sucess
        setAuthenticated(false)
        setAccessLevelNum(null)
      } else console.error(`logOutUser Failed! ${louResp.message}`)
      history.replace('/auth/login') // route to login
    }
    setBackdropConfig({
      show: false,
    })
    return { output, status, message }
  }
  // ****************************************************** END

  // ************** USE EFFECT **************************** START
  useEffect(() => {
    /**
     * Store drawingPath if the url is a drawingURL to route to
     * it after logging in
     */
    const pathName = new URL(window.location.href).pathname
    if (pathName === '/draw') {
      const pathParam = new URL(window.location.href).searchParams.get('path')
      const drawingPath = `${pathName}?path=${pathParam}`
      localStorage.setItem('drawingPath', drawingPath)
    }
  }, [])

  useEffect(() => {
    let removeListener = null
    /**
     * Route to login page if not authenticated!
     */
    if (authenticated) {
      removeListener = liveUpdateAccessLevel()
      const drawingPath = localStorage.getItem('drawingPath')
      if (drawingPath) { // if drawingPath exists route user to draw page with the path
        history.replace(drawingPath)
      } else {
        history.replace('/home')
      }
    } else {
      history.replace('/auth/login') // route to login
    }
    return () => { // cleanup
      if (removeListener) removeListener()
    }
  }, [authenticated, liveUpdateAccessLevel])
  // ************** USE EFFECT **************************** END

  return (
    <AppContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        logout: logoutHandler,
        onLogin: onLoginHandler,
        accessLevelNum,
        setAccessLevelNum,
        userUID,
        setUserUID,
        displayName,
        setDisplayName,
        backdropConfig,
        setBackdropConfig,
        alertMessage,
        setAlertMessage,
        sharesQuota,
        setSharesQuota,
        showTutorial,
        setShowTutorial,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseLine />
        <Suspense
          fallback={(
            <LinearProgress />
          )}
        >
          <Router history={history}>{renderRoutes(routes)}</Router>
        </Suspense>
        {
          alertMessage.show && (
            <Alert
              severity={alertMessage.severity}
              onClose={() => {
                setAlertMessage({
                  show: false,
                  message: '',
                  severity: 'success',
                })
              }}
              style={{
                position: 'fixed',
                left: '50%',
                bottom: '8%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {alertMessage.message || '-'}
              </div>
            </Alert>
          )
        }
        <Backdrop
          open={backdropConfig.show}
          style={{
            zIndex: 1,
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {backdropConfig.showProgress && (
            <CircularProgress
              color="secondary"
              style={{
                margin: 20
              }}
            />
          )}
          {backdropConfig.showMessage && (
            <div>
              {backdropConfig.message || '-'}
            </div>
          )}
        </Backdrop>
        <TutorialModal
          open={showTutorial}
          onClose={() => {
            localStorage.setItem('showTutorial', 'no') // dont show tutorial next time
            setShowTutorial(false)
          }}
        />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
