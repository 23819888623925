import React, {
} from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// **************** IMPORT GIFS *************************** START
import DeleteDrawingTutorialGif from './gifs/delete-drawing.gif'
// **************** IMPORT GIFS *************************** END


const DeleteDrawingTutorial = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
      }}
    >
      <Typography
        variant="h5"
      >
        Tutorial: Delete Drawing
      </Typography>
    </div>
    <Divider />
    <div
      style={{
        textAlign: 'center',
        height: '80%',
        width: '100%',
        backgroundColor: '#232323'
      }}
    >
      <img
        src={DeleteDrawingTutorialGif}
        alt="logout_gif"
        style={{
          height: '100%',
        }}
      />
    </div>
    <Divider />
    <div
      style={{
        padding: '5px 10px',
        backgroundColor: '#3a3a3a',
      }}
    >
      <Typography
        variant="body2"
      >
        • To delete your drawing, Click on the &apos;Drawer&apos; icon on
        the top right of your drawing on the Home page.
        <br />
        • Then click on the &apos;Delete Drawing&apos;.
        <br />
        • Confirm delete by pressing the &apos;DELETE&apos; button. Warning! This action is
        irreversible!
      </Typography>
    </div>
  </>
)


DeleteDrawingTutorial.propTypes = {
}

DeleteDrawingTutorial.defaultProps = {
}

export default DeleteDrawingTutorial
