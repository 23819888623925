import axios from 'axios';

const instance = axios.create(); // test Instance

// ----------------- main instance --------------- start
const axiosClient = axios.create({
  // baseURL: 'https://us-central1-share-draw-project.cloudfunctions.net/canvasFunction', // Production
  baseURL: 'https://us-central1-sharedrawcc.cloudfunctions.net/canvasFunction', // Final Production
  // baseURL: 'http://192.168.18.4:5000/share-draw-project/us-central1/canvasFunction', // Working
  // baseURL: 'http://192.168.18.4:5000/sharedrawcc/us-central1/canvasFunction', // Final Working
  timeout: 30000, // 30 sec timeout
});

/** request interceptor to add headers ----------- start */
axiosClient.interceptors.request.use((config) => {
  // get vars for setting header
  // eslint-disable-next-line no-undef
  const authorization = localStorage.getItem('authorization')

  if (!authorization) {
    console.error('authorization invalid!')
  }
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    authorization: `Bearer ${authorization}`,
  }
  return config
}, (error) => {
  console.error('Error! ', error)
  // Do something with request error
  return Promise.reject(error);
})
/** request interceptor to add headers ----------- start */

// ----------------- main instance --------------- end


// ------------- exports -------------------------- start
export { axiosClient as axios } // for Main server APIs
export default instance; // default for testing (mock)
// ------------- exports -------------------------- end
