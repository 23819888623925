import React, {
} from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// **************** IMPORT GIFS *************************** START
import logoutTutorialGif from './gifs/logout-tutorial-1.gif'
// **************** IMPORT GIFS *************************** END


const LogoutTutorial = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
      }}
    >
      <Typography
        variant="h5"
      >
        Tutorial: Logout
      </Typography>
    </div>
    <Divider />
    <div
      style={{
        textAlign: 'center',
        height: '80%',
        width: '100%',
        backgroundColor: '#232323'
      }}
    >
      <img
        src={logoutTutorialGif}
        alt="logout_gif"
        style={{
          height: '100%',
        }}
      />
    </div>
    <Divider />
    <div
      style={{
        padding: '5px 10px',
        backgroundColor: '#3a3a3a',
      }}
    >
      <Typography
        variant="body2"
      >
        • On the Home Page, click on the &apos;Menu&apos; icon (bottom left) to access the menu
        and the click the logout option.
        <br />
      </Typography>
    </div>
  </>
)


LogoutTutorial.propTypes = {
}

LogoutTutorial.defaultProps = {
}

export default LogoutTutorial
