
/**
 * Define Access Level Mapping here.
 * - AccessLevel will provide diff variant of dashb
 * for users with diff access level.
 * - This will be used to convert string accesslevel to num
 * and vice versa.
 */
export const accessLevelDefs = [
  {
    id: 'viewer',
    num: 0,
    label: 'Viewer',
  },
  {
    id: 'user',
    num: 1,
    label: 'User',
  },
  {
    id: 'admin',
    num: 2,
    label: 'Admin',
  },
  {
    id: 'superadmin',
    num: 3,
    label: 'SuperAdmin',
  },
]

/**
 * Get Access level number from String
 * @param {*} access_level_str String acces level got from backend via login API
 */
export const getAccessLevelNumFromId = (access_level_str) => {
  if (access_level_str) { // if valid
    const defObj = accessLevelDefs.find((def) => def.id === access_level_str)
    if (defObj) { // if found
      return defObj.num
    }
    // if not found
    console.error('Access level not found in defs!')
  } else {
    console.error('Invalid access_level_str')
  }
  return null // retrun null if not found
}


/**
 * Get Access level Label from String
 * @param {*} access_level_str String acces level got from backend via login API
 */
export const getAccessLevelLabelFromId = (access_level_str) => {
  if (access_level_str) { // if valid
    const defObj = accessLevelDefs.find((def) => def.id === access_level_str)
    if (defObj) { // if found
      return defObj.label
    }
    // if not found
    console.error('Access level not found in defs!')
  } else {
    console.error('Invalid access_level_str')
  }
  return null // retrun null if not found
}


/**
 * Get Access level string ID from num
 * @param {*} access_level_num
 */
export const getAccessLevelIdFromNum = (access_level_num) => {
  if (access_level_num || access_level_num === 0) { // if valid
    const defObj = accessLevelDefs.find((def) => def.num === access_level_num)
    if (defObj) { // if found
      return defObj.id
    }
    // if not found
    console.error('Access level not found in defs!')
  } else {
    console.error('Invalid access_level_num')
  }
  return null // retrun null if not found
}


/**
 * Get Access level Label from num
 * @param {*} access_level_num
 */
export const getAccessLevelLabelFromNum = (access_level_num) => {
  if (access_level_num || access_level_num === 0) { // if valid
    const defObj = accessLevelDefs.find((def) => def.num === access_level_num)
    if (defObj) { // if found
      return defObj.label
    }
    // if not found
    console.error('Access level not found in defs!')
  } else {
    console.error('Invalid access_level_num')
  }
  return null // retrun null if not found
}


export default null
