import React, {
} from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// **************** IMPORT GIFS *************************** START
import UndoDrawingTutorialGif from './gifs/undo-tutorial-1.gif'
// **************** IMPORT GIFS *************************** END


const UndoDrawingTutorial = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
      }}
    >
      <Typography
        variant="h5"
      >
        Tutorial: Undo Drawing
      </Typography>
    </div>
    <Divider />
    <div
      style={{
        textAlign: 'center',
        height: '80%',
        width: '100%',
        backgroundColor: '#232323'
      }}
    >
      <img
        src={UndoDrawingTutorialGif}
        alt="logout_gif"
        style={{
          height: '100%',
        }}
      />
    </div>
    <Divider />
    <div
      style={{
        padding: '5px 10px',
        backgroundColor: '#3a3a3a',
      }}
    >
      <Typography
        variant="body2"
      >
        • Incase you mess up, do
        {' '}
        <strong>NOT</strong>
        {' '}
        click on ShareDraw!
        <br />
        • Click on the &apos;↶&apos; icon on the Draw Page to undo strokes.
      </Typography>
    </div>
  </>
)


UndoDrawingTutorial.propTypes = {
}

UndoDrawingTutorial.defaultProps = {
}

export default UndoDrawingTutorial
