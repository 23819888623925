/* eslint-disable import/no-duplicates */
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';


// Firebase App configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBu5j6gIGLeqtDBt0RXgUXWPC_YvkiurQU',
  authDomain: 'sharedrawcc.firebaseapp.com',
  databaseURL: 'https://sharedrawcc.firebaseio.com',
  projectId: 'sharedrawcc',
  storageBucket: 'sharedrawcc.appspot.com',
  messagingSenderId: '768362373371',
  appId: '1:768362373371:web:1b38b58661e663acb2505e',
  measurementId: 'G-2R7EC3J2SX',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// ----------------------- auth changed ------------ start
firebase.auth().onAuthStateChanged(async (user) => {
  console.log('trigger onAuth State changed ------------')
  if (user) {
    // User is signed in.
    const idToken = await user.getIdToken(true) // get JWT
    // eslint-disable-next-line no-undef
    localStorage.setItem('authorization', idToken)
    // ...
  } else {
    // User is signed out.
    // eslint-disable-next-line no-undef
    // console.log('firebase clear()')
    // // clear local store but preserve drawingPath (if stored)
    // const drawingPath = localStorage.getItem('drawingPath')
    // localStorage.clear()
    // if (drawingPath) {
    //   localStorage.setItem('drawingPath', drawingPath)
    // }
    // ...
  }
})
// ----------------------- auth changed ------------ end

export default firebase
