/** firebase for auth */
import firebase from './firebase';
import { axios } from './utils/axios'


/**
 * Logout User
 */
export const logOutUser = async () => {
  // eslint-disable-next-line prefer-const
  let output = null
  let status = false
  let message = ''

  console.log('TCL: logOutUser -> logOutUser')
  try {
    await firebase.auth().signOut()
    // eslint-disable-next-line no-undef
    localStorage.clear()
    // Sign-out successful.
    console.log('Sign Out Success')
    status = true
    message = 'sign out success!'
  } catch (error) {
    // An error happened.
    console.error('Sign out Error!', error)
    message = `sign out failed! error:${error}`
    status = false
  }
  return { output, status, message }
}


/**
 *  updateAuthClaim for curr user (Refresh Access Level)
 * - Call this just before user logout
 */
export const updateAuthClaims = async () => {
  // eslint-disable-next-line prefer-const
  let output = null
  let status = false
  let message = ''

  try {
    const endpt = '/auth/updateAuthClaims'
    const toSend = {
      update: true,
    }
    const response = await axios.post(endpt, toSend)
    const { data } = response
    if (data.status) {
      if (data.output && Object.keys(data.output).length > 0) {
        output = data.output
        status = true
        message = data.message
      } else {
        throw new Error(`Output Invalid! resp message: ${data.message}`)
      }
    } else {
      throw new Error(`status false with message: ${data.message}`)
    }
  } catch (error) {
    // An error happened.
    status = false
    message = `updateAuthClaims Error! ${error.toString()}`
    console.error(message)
  }
  return { output, status, message }
}

export default null
