import React, {
} from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// **************** IMPORT GIFS *************************** START
import ShareDrawingTutorialGif from './gifs/copy-share-link.gif'
// **************** IMPORT GIFS *************************** END


const ShareDrawingTutorial = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
      }}
    >
      <Typography
        variant="h5"
      >
        Tutorial: Share Drawing
      </Typography>
    </div>
    <Divider />
    <div
      style={{
        textAlign: 'center',
        height: '80%',
        width: '100%',
        backgroundColor: '#232323'
      }}
    >
      <img
        src={ShareDrawingTutorialGif}
        alt="logout_gif"
        style={{
          height: '100%',
        }}
      />
    </div>
    <Divider />
    <div
      style={{
        padding: '5px 10px',
        backgroundColor: '#3a3a3a',
      }}
    >
      <Typography
        variant="body2"
      >
        • Click on the &apos;Settings&apos; icon on the Draw Page.
        <br />
        • Then click on &apos;SHARE CANVAS&apos; button.
        <br />
        • Then Click on &apos;COPY LINK&apos; to copy link to clipboard.
        <br />
        • Paste this link and share it with your friends to collaborate on this drawing!
        <br />
        • Warning! Anyone with this link can add to your drawing before you finish drawing.
        Share this link carefully!
        <br />
      </Typography>
    </div>
  </>
)


ShareDrawingTutorial.propTypes = {
}

ShareDrawingTutorial.defaultProps = {
}

export default ShareDrawingTutorial
