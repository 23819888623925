import React, {
} from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// **************** IMPORT GIFS *************************** START
import BrushSettingsTutorialGif from './gifs/brush-settings-1.gif'
// **************** IMPORT GIFS *************************** END


const BrushSettingsTutorial = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
      }}
    >
      <Typography
        variant="h5"
      >
        Tutorial: Brush Settings
      </Typography>
    </div>
    <Divider />
    <div
      style={{
        textAlign: 'center',
        height: '80%',
        width: '100%',
        backgroundColor: '#232323'
      }}
    >
      <img
        src={BrushSettingsTutorialGif}
        alt="logout_gif"
        style={{
          height: '100%',
        }}
      />
    </div>
    <Divider />
    <div
      style={{
        padding: '5px 10px',
        backgroundColor: '#3a3a3a',
      }}
    >
      <Typography
        variant="body2"
      >
        • Click on the &apos;🖌&apos; icon on the Draw Page.
        <br />
        • Adjust brush color/stroke.
        <br />
        • Click &apos;Save&apos; and start drawing!
        <br />
      </Typography>
    </div>
  </>
)


BrushSettingsTutorial.propTypes = {
}

BrushSettingsTutorial.defaultProps = {
}

export default BrushSettingsTutorial
