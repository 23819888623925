/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'


export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />,
  },
  {
    path: '/auth',
    component: lazy(() => import('./layouts/AuthLayout')),
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('./views/Login')),
      },
      {
        path: '/error-404',
        exact: true,
        component: () => <h1>Error 404: Page Not found!</h1>,
      },
    ],
  },
  {
    path: '*',
    component: lazy(() => import('./layouts/MainLayout')),
    routes: [
      {
        path: '/home',
        exact: true,
        component: lazy(() => import('./views/Home')),
      },
      {
        path: '/draw',
        exact: true,
        component: lazy(() => import('./views/Draw')),
      },
      {
        path: '/error-404',
        exact: true,
        component: () => <h1>Error 404: Page Not found!</h1>,
      },
    ],
  },
  {
    component: () => <Redirect to="/error-404" />,
  },
];
