import React, {
} from 'react';
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
// **************** IMPORT GIFS *************************** START
import ViewUsageTutorialGif from './gifs/view-usage-tutorial-1.gif'
// **************** IMPORT GIFS *************************** END


const ViewUsage = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
      }}
    >
      <Typography
        variant="h5"
      >
        Tutorial: View Usage
      </Typography>
    </div>
    <Divider />
    <div
      style={{
        textAlign: 'center',
        height: '80%',
        width: '100%',
        backgroundColor: '#232323'
      }}
    >
      <img
        src={ViewUsageTutorialGif}
        alt="logout_gif"
        style={{
          height: '100%',
        }}
      />
    </div>
    <Divider />
    <div
      style={{
        padding: '5px 10px',
        backgroundColor: '#3a3a3a',
      }}
    >
      <Typography
        variant="body2"
      >
        • The Number of drawings created per day are limited!
        <br />
        • You can check your usage stats by clicking on the
        &apos;Menu&apos; icon on the bottom left.
        <br />
        • Here you can see your maximum shares available per drawing as well.
      </Typography>
    </div>
  </>
)


ViewUsage.propTypes = {
}

ViewUsage.defaultProps = {
}

export default ViewUsage
